#homePageContainer {
  height: 100vh;
  overflow: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  width: 341px;
  justify-content: space-between;
  margin-top: 20px;
}

.logo {
  width: 272px;
  height: 73px;
}

.homeInput {
  width: 335px;
  height: 35px;
  margin-top: 20px;
}
.sidebar {
  margin: 0;
  padding: 0;
  width: 250px;
  background: #464646;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.logo {
  width: 191px;
  height: auto;
}

.sidebar a {
  display: block;
  color: white;
  font-size: 14px;
  padding: 10px 30px;
  text-decoration: none;
}

.sidebar-header {
  padding: 40px 20px;
}

/* @media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
} */

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  .sidebar a {
    text-align: center;
    float: none;
  }
}

.blindRow {
  margin-left: 250px;
}

@media screen and (max-width: 700px) {
  .blindRow {
    margin-left: 0;
  }
}

.blind-wrapper {
  width: 250px;
  height: 320px;
  background: #80808e;
  margin: 30px;
  opacity: 0.756;
  border-radius: 3px;
}

.title-divider {
  height: 2px;
  width: 210px;
  margin-left: 20px;
  background: black;
}

.blind-title {
  color: black;
  padding: 10px;
  text-align: center;
}

.slider-container {
  padding: 5px 20px;
  display: flex;
}

.slider-title {
  padding: 5px 20px;
  margin-top: 10px;
}

