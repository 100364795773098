.sidebar {
  margin: 0;
  padding: 0;
  width: 250px;
  background: #464646;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.logo {
  width: 191px;
  height: auto;
}

.sidebar a {
  display: block;
  color: white;
  font-size: 14px;
  padding: 10px 30px;
  text-decoration: none;
}

.sidebar-header {
  padding: 40px 20px;
}

/* @media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  div.content {
    margin-left: 0;
  }
} */

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
  .sidebar a {
    float: left;
  }
  .sidebar a {
    text-align: center;
    float: none;
  }
}
