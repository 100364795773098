#homePageContainer {
  height: 100vh;
  overflow: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.buttonContainer {
  display: flex;
  width: 341px;
  justify-content: space-between;
  margin-top: 20px;
}

.logo {
  width: 272px;
  height: 73px;
}

.homeInput {
  width: 335px;
  height: 35px;
  margin-top: 20px;
}