.blind-wrapper {
  width: 250px;
  height: 320px;
  background: #80808e;
  margin: 30px;
  opacity: 0.756;
  border-radius: 3px;
}

.title-divider {
  height: 2px;
  width: 210px;
  margin-left: 20px;
  background: black;
}

.blind-title {
  color: black;
  padding: 10px;
  text-align: center;
}

.slider-container {
  padding: 5px 20px;
  display: flex;
}

.slider-title {
  padding: 5px 20px;
  margin-top: 10px;
}
